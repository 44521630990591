<template>
    <div style="min-width: 1360px;">
        <Header/>
        <headerBanner :content="bannerContent" :price="bannerPrice" @btnClick="bannerClick" title="QMS系统" imgStyle="height:0%;height:0" contentStyle="width:100%" :bodyStyle="bodyStyle"/>
        
        <div class="card">
            <div class="card-container">
                <div class="card--title" style="margin-bottom: 40px;">QMS系统能为你提供</div>
                <div class="card-boxs">
                <div style="width: 450px;">
                    <div class="cell-item" v-for="card of cardList" :key="card.id">
                        <div class="or-title">{{card.title}}</div>
                        <div class="orContent" >{{card.content}}</div>
                    </div>
                </div>
                <img :src="require('@/assets/img/home/qms.png')" style="width: 710px;height: 620px;">
                </div>
            </div>
        </div>

        <div class="qms-box">
            <div class="card-title">全方位满足企业管理需求</div>
            <div class="logistics-box">
                <div class="logistics-box-cell" v-for="(item,i) in QMSList" :key="i">
                <div class="logistics-box-cell-title">
                    <div class="logistics-box-cell-title-left">{{ item.title }}</div>
                    <div class="logistics-box-cell-title-right">{{ item.tips }}</div>
                </div>
                <div class="logistics-box-content">{{ item.content }}</div>
                </div>
            </div>
        </div>
        <applyTrial ref="dialog" :servicePlatform="3"/>
        <new-cloude-footer/>
    </div>
</template>
<script>
import Header from "@/views/home/newCloudeHeader.vue";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
export default {
    name: 'MESsystem',
    components: {
        Header,
        headerBanner,
        applyTrial,
        NewCloudeFooter
    },
    data() {
        return {
            bannerPrice:'市场价格：220000元',
            bannerContent: 'QMS系统适用于所有需要管理和提升产品和服务质量的企业，包括制造业、服务业、医疗行业等各种行业和规模的组织。',
            bodyStyle: `background-image:url(${require('@/assets/img/home/WDKI0LH_y.png')});background-size: contain;background-repeat: no-repeat;background-position: right 25px;`,
            cardList:[
                {
                    title:'标准化流程',
                    content:'系统按照国际质量管理标准设计，帮助企业建立标准化的质量管理流程'
                },
                {
                    title:'易于使用',
                    content:'用户友好的操作界面，便于员工快速学习和操作'
                },
                {
                    title:'实时监控',
                    content:'实时数据监控和报告功能，帮助管理层及时了解质量状况'
                },
                {
                    title:'可追溯性',
                    content:'完整的记录和报告功能，确保质量活动的可追溯性'
                },
                {
                    title:'持续改进',
                    content:'内置分析和改进工具，支持企业持续提升产品和服务质量'
                },
                {
                    title:'高度集成',
                    content:'可以与其他企业系统如ERP、MES等集成，实现信息共享和流程协同'
                },
            ],
            QMSList:[
                {
                    title: '文档管理',
                    content: '提供文档创建、审批、发布和存档的全流程管理，确保文档的标准化和版本控制'
                },
                {
                    title: '供应商管理',
                    content: '评估和监控供应商的性能，包括供应商选择、审核、评分和改进'
                },
                {
                    title: '原材料检验',
                    content: '对进料进行检验和记录，保证原材料满足质量要求'
                },
                {
                    title: '生产过程控制',
                    content: '在生产过程中实施质量控制点，进行实时监控和异常处理'
                },
                {
                    title: '成品检验与测试',
                    content: '对成品进行全面检验和测试，确保最终产品质量'
                },
                {
                    title: '不合格品控制',
                    content: '对不符合质量标准的产品进行隔离、评审和处理'
                },
                {
                    title: '客户投诉处理',
                    content: '记录和分析客户反馈，及时响应并采取纠正措施'
                },
                {
                    title: '内部审核',
                    content: '定期进行内部质量审核，检查和评估质量管理体系的有效性'
                },
                // {
                //     title: '管理评审',
                //     content: '高层管理人员定期评审质量管理体系，确保体系的持续改进'
                // },
                // {
                //     title: '数据分析与改进',
                //     content: '收集和分析质量数据，识别改进机会，实施持续改进计划'
                // },
                // {

                // },
                // {

                // },
            ],
        }
    },
    methods: {
        
    }
}
</script>
<style lang="scss" scoped>

.card {
  padding: 60px 0;
  &-container {
    width: 1200px;
    margin: auto;
  }
}

.card--title{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.card-boxs{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.or-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  margin-top: 15px;
}

.orContent {
  padding-bottom: 24px;
  line-height: 1.5;
  text-align: justify;
  font-size: 14px;
  color: #333;
}

.cell-item {
  border-bottom: 1px dashed #1935DE;
  &:last-child {
    border: 0;
  }
}

.qms-box {
  padding: 60px 0;
  background-color: rgba(247, 248, 254, 1);
}

.card-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: auto;
  padding-bottom: 30px;
}

.logistics-box {
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .blue-icon {
    width: 80px;
    height: 80px;
    background: #1935DE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  &-cell {
    width: 290px;
    height: 240px;
    border: 1px solid #000000;
    box-sizing: border-box;
    background-color: #fff;
    padding: 30px 30px 0;
    margin-bottom: 12px;

    &-title {
      font-size: 18px;
      text-align: center;

      &-left {
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }

      &-right {
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
    }

    // &:nth-child(11) {
    //     background-color: transparent;
    //     border: none;
    //     .logistics-box-content{
    //        border-top: none; 
    //     }
    // }

    // &:nth-child(12) {
    //     background-color: transparent;
    //     border: none;
    //     .logistics-box-content{
    //        border-top: none; 
    //     }
    // }

    .logistics-box-content {
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid #000000;
      line-height: 1.8;
      font-family: 'SourceHanSansCN';

    }
  }
}

</style>